.kontakt-1 {
    overflow-y: auto; /* Aktiviert vertikales Scrollen */
    padding: 16px; /* Optional: Innenabstand für einen schöneren Look */
    box-sizing: border-box; /* Berücksichtigt Padding in der max-height */
  }

.kontakt {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
}

.kontakt_alternative {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
}

.produkt_kontakt_head {
    display: flex;
}

.produkt_kontakt_head > img {
    width: 30px;
    margin-left: 15px;
}

.produkt_kontakt_head > h4 {
    font-size: 26px;
    margin-bottom: 5px;
}

.produkt_kontakt > h4 {
    font-size: 25px;
    margin-bottom: 5px;
}

.produkt_kontakt > p {
    margin-right: 6vw;
}

.formular {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 600px;
    margin: 3% 0;
    box-sizing: border-box;
}

.formular > form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.formular > form > button {
    /* Alle vorherigen Stile entfernen, da sie jetzt in unseren vereinheitlichten Regeln sind */
}

.input {
    /* Alle vorherigen Stile entfernen, da sie jetzt in unseren vereinheitlichten Regeln sind */
}

.input2 {
    -webkit-appearance:none;
    -moz-appearance: none; 
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='black'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
    background-size: 12px;
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-color: rgba(231, 231, 231, 0.644);
    width: 75%;
    height: 45px;
    margin: 5px 0 15px 0;
    font-weight: 500;
    letter-spacing: 2px;
    border-color: transparent;
    border-radius: 5px;
    border-right-width: 10px;
    padding-left: 20px;
}

.input3 {
    /* Alle vorherigen Stile entfernen, da sie jetzt in unseren vereinheitlichten Regeln sind */
}

.input4 {
    /* Alle vorherigen Stile entfernen, da sie jetzt in unseren vereinheitlichten Regeln sind */
}

.input::placeholder {
    color: gray;
    transition: color 0.3s ease;
}

.input:focus::placeholder {
    color: transparent;
}

.input:hover {
    font-weight: 500;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 6px 18px -9px rgba(0, 0, 0, 0.15);
}
.input:focus {
    font-weight: 500;
    border:none;
    outline: none;
    border-radius: 5px;
    box-shadow: 0px 6px 18px -9px rgba(0, 0, 0, 0.15);
}

.checkbox_container {
    font-size: 14px;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
}

.checkbox_container > label {
    cursor: pointer;
    display: flex;
    padding-top: 15px;
    padding-bottom: 15px;
    align-items: center;
}

.checkbox_container > input[type="checkbox"] {
    cursor: pointer;
    opacity: 0;
    position: absolute;
}

.checkbox_container > label::before {
    content: "";
    width:1.5em;
    height: 1.5em;
    border: 2px solid rgb(172, 172, 172);
    border-radius: 100px;
    margin-right: 0.5em;
    background-color: white;
}

.checkbox_container > label:hover::before, 
.checkbox_container > input [type ="checkbox"]:hover + label::before {
    border: 2px solid rgb(132, 216, 64) ;
}

.checkbox_container > input[type="checkbox"]:focus + label::before {
    box-shadow: 0 0 px black;
}

.checkbox_container > input[type="checkbox"]:checked + label::before {
    background-color: rgb(132, 216, 64) ;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.kontakt_foto {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
}

.kontakt_foto > img {
    display: flex;
    width:  70%;
    height: 60%;
    object-fit: cover;
    border-radius: 20px;
    z-index: 2;
}

.input5 {
    /* Alle vorherigen Stile entfernen, da sie jetzt in unseren vereinheitlichten Regeln sind */
}

.input_6 {
    display: none;
}

.produkt_hidden {
    display: none;
}

.image-upload-container-wrapper {
    /* Alle vorherigen Stile entfernen, da sie jetzt in unseren vereinheitlichten Regeln sind */
}

.image-upload-container-wrapper > img {
    width: 25px;
    opacity: 0.5;
    margin-right: 10px;
}

.image-upload-container-wrapper:hover > img {
    opacity: 1;
}

.image-upload-container-wrapper:hover {
    color: black;
}

.image-upload-container {
    /* Alle vorherigen Stile entfernen, da sie jetzt in unseren vereinheitlichten Regeln sind */
}

.image-upload-container span {
    font-size: 14px;
    color: #999;
    text-align: center;
}

.image-preview {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.remove-image-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hidden-image-input {
    display: none;
}

.submit-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner {
    width: 20px;
    height: 20px;
    border: 2px solid transparent;
    border-top: 2px solid #ffffff;
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 1040px) {
    .formular {
        max-width: 100%;
        margin: 3% auto;
    }
    
    .kontakt, .kontakt_alternative {
        position: static;
        margin: 5% auto;
        padding: 0 6%;
        max-width: 100%;
    }
    
    .input, .input3, .input4, .input5, .input-container input,
    .image-upload-container, .submit-button, form > button {
        width: 100%;
        box-sizing: border-box;
    }
    
    .checkbox_container > label::before {
        width: 24px; /* Etwas kleiner für bessere mobile Ansicht */
        height: 24px;
        margin-right: 10px;
    }
    
    form > button, .submit-button {
        margin-bottom: 30px; /* Mehr Platz am Ende des Formulars */
    }
    
    .input-container {
        width: 100%;
    }
    
    .input-container input {
        width: 100%;
    }
}

@media (max-width: 600px) {
    .formular {
        margin: 3% auto;
    }
    
    .kontakt, .kontakt_alternative {
        padding: 0 4%;
    }
    
    .image-upload-container-wrapper {
        flex-direction: column;
        text-align: center;
    }
    
    .image-upload-container-wrapper > img {
        margin-right: 0;
        margin-bottom: 10px;
    }
    
    .image-upload-container {
        padding: 15px 10px;
        min-height: 80px;
        display: flex;
        flex-direction: column;
    }
    
    .checkbox_container {
        margin: 15px 0;
    }
    
    .checkbox_container > label {
        font-size: 13px;
        line-height: 1.4;
    }
    
    .error-message {
        font-size: 11px;
        margin-top: -4px;
        margin-bottom: 10px;
    }
}

/* Verbesserte Styles für die Formularvalidierung */
.input-container {
    position: relative;
    width: 100%;
    display: block;
    margin-bottom: 0;
}

.input-container input {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 0; /* Überschreibe den standardmäßigen unteren Rand */
}




.input-error {
    border: 1px solid #ff3a3a !important;
    background-color: #fff2f2;
}

.error-message {
    color: #ff3a3a;
    font-size: 12px;
    margin-top: -6px;
    margin-bottom: 12px; /* Erhöht für besseren Abstand */
    margin-left: 5px;
    display: block;
}

/* Verbesserter Spinner für Feedback während des Sendens */
.spinner {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 3px solid rgba(255,255,255,0.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    to { transform: rotate(360deg); }
}

/* Toast-Nachrichten für verbesserte Benutzerrückmeldung */
.toast-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 9999;
}

.toast {
    background-color: #4CAF50;
    color: white;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 300px;
}

.toast.error {
    background-color: #ff3a3a;
}

.toast-close {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px;
}

/* Vereinheitlichtes Styling für alle Eingabe-Elemente */
.input, .input3, .input4, .input5, .input-container input {
    box-sizing: border-box;
    width: 100%;
    border: none;
    border-radius: 5px;
    background-color: rgb(241, 241, 241);
    font-family: 'Noto Serif JP';
    transition: all 0.2s ease;
}

.input, .input-container input {
    height: 45px;
    padding-left: 20px;
    margin: 0 0 12px 0;
    font-weight: 500;
    letter-spacing: 1px;
}

/* Dropdown Styling vereinheitlichen */
.input3, .input4 {
    height: 45px;
    padding-left: 20px;
    margin: 0 0 12px 0;
    font-weight: 500;
    letter-spacing: 1px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='green'><polygon points='0,0 100,0 50,50'/></svg>");
    background-repeat: no-repeat;
    background-position: calc(100% - 15px) center;
    background-size: 12px;
    padding-right: 40px; /* Platz für das Dropdown-Icon */
}

/* Konsistentes Hover- und Fokus-Styling */
.input:hover, .input3:hover, .input4:hover, .input5:hover, .input-container input:hover {
    box-shadow: 0px 6px 18px -9px rgba(0, 0, 0, 0.15);
}

.input:focus, .input3:focus, .input4:focus, .input5:focus, .input-container input:focus {
    outline: none;
    box-shadow: 0px 6px 18px -9px rgba(0, 0, 0, 0.15);
}

/* Textarea-Styling */
.input5 {
    height: 90px;
    padding: 10px 20px;
    margin: 0 0 12px 0;
    resize: none;
}

/* Bild-Upload-Bereich verbessern */
.image-upload-container {
    width: 100%;
    margin: 0 0 12px 0;
    padding: 15px;
    min-height: 50px;
    border: 1px dashed #ccc;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
}

.image-upload-container-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.image-upload-container-wrapper > img {
    width: 25px;
    opacity: 0.7;
    margin-right: 15px;
}

/* Submit-Button Styling */
.submit-button, form > button {
    width: 100%;
    height: 55px;
    border-radius: 100px;
    border: none;
    background-color: var(--weber);
    color: white;
    font-weight: 500;
    font-size: 14px;
    margin-top: 12px;
    letter-spacing: 2px;
    cursor: pointer;
    transition: all 200ms ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.submit-button:hover, form > button:hover {
    transform: scale(1.01);
    box-shadow: 0px 6px 18px -9px rgba(0, 0, 0, 0.35);
}

/* Checkbox-Container verbessern */
.checkbox_container {
    margin: 10px 0;
    display: flex;
    align-items: center;
}

/* Verbessere Layout für Seiten mit dem Formular */
.produkt_kontakt {
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* Verbessere das Layout auf Produktseiten */
.produkt_information {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}