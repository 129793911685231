/* Neues Layout für die Kontaktseite mit spezifischen Klassennamen */

/* Hauptcontainer für die Kontaktseite */
.weber-kontakt-information {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 6%;
    margin-bottom: 6%;
}

/* Layout für die zweispaltige Ansicht */
.weber-kontakt-layout {
    display: flex;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
    gap: 30px;
}

/* Linke Spalte mit Kontaktformular */
.weber-kontakt-formular-bereich {
    flex: 1;
    min-width: 320px;
}

.weber-kontakt-formular-titel,
.weber-kontakt-info-titel {
    font-size: 26px;
    color: var(--weber, #333333);
    margin-bottom: 20px;
    font-weight: 600;
}

.weber-kontakt-formular-container {
    background-color: #fcfcfc;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

/* Rechte Spalte mit den Info-Boxen */
.weber-kontakt-info-bereich {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Gemeinsame Styles für beide Info-Boxen */
.weber-kontakt-adresse-box,
.weber-kontakt-telefon-box {
    width: 100%;
    transition: all 0.3s ease;
}

/* Container innerhalb der Info-Boxen */
.weber-kontakt-box-container {
    display: flex;
    justify-content: space-between;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(238, 238, 238);
    width: 100%;    
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    padding: 0;
    box-sizing: border-box;
}

.weber-kontakt-box-container:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
}

/* Bereich für den Inhalt innerhalb der Info-Boxen */
.weber-kontakt-box-inhalt {
    margin: 15px 0 15px 25px;
    color: var(--weber);
    flex-grow: 1;
}

/* Text-Styling innerhalb der Info-Boxen */
.weber-kontakt-box-inhalt > p {
    font-size: 15px;
    color: black;
    margin-bottom: 5px;
}

.weber-kontakt-box-inhalt > h3 {
    font-size: 20px;
    margin-bottom: 8%;
    word-wrap: break-word;
}

.weber-kontakt-box-inhalt > h4 {
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 3px;
    word-wrap: break-word;
}

.weber-kontakt-box-inhalt > h4 > span {
    font-size: 16px;
    font-weight: 700;
    color: black;
}

/* Icon-Bereich innerhalb der Info-Boxen */
.weber-kontakt-box-icon {
    width: 8%;
    margin: 10px 15px 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.weber-kontakt-box-icon > img {
    width: 40px;
    height: auto;
}

/* Media Queries für Responsive Design */
@media (max-width: 1050px) {
    .weber-kontakt-layout {
        flex-direction: column-reverse;
        gap: 20px;
    }

    .weber-kontakt-formular-bereich,
    .weber-kontakt-info-bereich {
        width: 100%;
        max-width: 700px;
        margin: 0 auto;
    }
    
    .weber-kontakt-info-bereich {
        gap: 15px;
        margin-bottom: 25px;
    }
    
    .weber-kontakt-info-titel {
        text-align: left;
        margin-left: 20px;
    }
    
    .weber-kontakt-formular-titel {
        text-align: left;
        margin-left: 20px;
    }

    .weber-kontakt-box-icon > img {
        width: 35px;
    }
}

@media (max-width: 768px) {
    .weber-kontakt-layout {
        padding: 0 15px;
    }
    
    .weber-kontakt-info-bereich {
        gap: 12px;
    }
    
    .weber-kontakt-formular-titel,
    .weber-kontakt-info-titel {
        font-size: 22px;
        margin-bottom: 12px;
    }
    
    
    .weber-kontakt-box-icon {
        margin: 8px 10px 8px 0;
    }
    
    .weber-kontakt-box-inhalt > h3 {
        font-size: 18px;
        margin-bottom: 6%;
    }
    
    .weber-kontakt-formular-container {
        padding: 0px;
    }
}

@media (max-width: 600px) {
    .weber-kontakt-layout {
        padding: 0 10px;
    }
    
    .weber-kontakt-formular-titel,
    .weber-kontakt-info-titel {
        font-size: 20px;
        margin-bottom: 10px;
    }
  
    
    .weber-kontakt-box-icon {
        margin: 8px 8px 8px 0;
    }

    .weber-kontakt-box-inhalt > h3 {
        font-size: 18px;
        margin-bottom: 5%;
    }

    .weber-kontakt-box-inhalt > h4 {
        font-size: 14px;
    }

    .weber-kontakt-box-inhalt > h4 > span {
        font-size: 15px;
    }
    
    .weber-kontakt-box-icon {
        width: 12%;
    }
    
    .weber-kontakt-box-icon > img {
        width: 30px;
    }
}

@media (max-width: 480px) {
    .weber-kontakt-information {
        margin-top: 4%;
    }
    
    .weber-kontakt-layout {
        padding: 0 8px;
        gap: 12px;
    }
    
    .weber-kontakt-formular-titel,
    .weber-kontakt-info-titel {
        font-size: 18px;
        margin-bottom: 8px;
    }
    
    .weber-kontakt-info-bereich {
        gap: 10px;
    }

    
    .weber-kontakt-box-icon {
        margin: 6px 6px 6px 0;
    }
    
    .weber-kontakt-box-inhalt > p {
        font-size: 13px;
    }
    
    .weber-kontakt-box-inhalt > h3 {
        font-size: 16px;
        margin-bottom: 4%;
    }
    
    .weber-kontakt-box-inhalt > h4 {
        font-size: 12px;
    }
    
    .weber-kontakt-box-inhalt > h4 > span {
        font-size: 14px;
    }
    
    .weber-kontakt-box-icon {
        width: 12%;
    }
    
    .weber-kontakt-box-icon > img {
        width: 25px;
    }
}